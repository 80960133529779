<template>
	<div>
		<f-card>
			<v-row v-for="(item, index) in messages" :key="index">
				<v-col cols="12" :class="{ admin: item.is_admin }">
					<f-ticket-response
						:user="item.name"
						:isAdmin="item.is_admin"
						:date="item.created_at">
						{{ item.text }}
					</f-ticket-response>
				</v-col>
			</v-row>
		</f-card>

		<f-card-spacer />

		<f-card>
			<v-row>
				<v-col cols="12">
					<f-card-title>{{ $t("pages.contact.view.message") }}</f-card-title>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-textarea v-model="ticket.message" outlined dense hide-details />
				</v-col>
			</v-row>
		</f-card>
	</div>
</template>

<script>
import FTicketResponse from "./Responses.vue";

export default {
	components: {
		FTicketResponse,
	},
	// data: () => ({
	//     id: null
	// }),
	computed: {
		ticket() {
			return this.$store.state.contact.view;
		},
		messages() {
			return this.$store.state.contact.view.messages;
		},
	},
	// created() {
	//     this.id = this.$route.params.id
	//     this.getData()
	// },
	// methods: {
	//     async getData() {
	//         this.$store.dispatch( 'contact/get', this.id )
	//     }
	// }
};
</script>
