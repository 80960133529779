<template>
	<div>
		<v-dialog v-model="showContent" width="500" @click:outside="hideModal">
			<v-card>
				<v-card-title>{{ $t("labels.btnEdit") }}</v-card-title>

				<v-card-text>
					{{
						$t(
							`pages.pos.tableHeaders.${distRegion}.${snakeToCamel(
								ariaValues.column
							)}`
						)
					}}:
					<v-text-field
						v-if="inputType == INPUT_TYPES.TEXT"
						v-model="ariaValues.value"
						outlined
						dense
						hide-details="auto"
						:error-messages="errorMessage"
						:error="errorMessage.length == 0 ? false : true"
						@keyup="keyUpHandler" />
					<v-text-field
						v-if="inputType == INPUT_TYPES.CURRENCY"
						:key="refresh"
						v-money="config"
						v-model="ariaValues.value"
						outlined
						dense
						hide-details="auto"
						:error-messages="errorMessage"
						:error="errorMessage.length == 0 ? false : true"
						@keyup="keyUpHandler" />
					<v-text-field
						v-if="inputType == INPUT_TYPES.NUMBER"
						v-model="ariaValues.value"
						outlined
						dense
						hide-details="auto"
						:error-messages="errorMessage"
						:error="errorMessage.length == 0 ? false : true"
						@keyup="keyUpHandler"
						type="number" />
					<v-date-picker
						full-width
						v-if="inputType == INPUT_TYPES.DATE"
						v-model="ariaValues.value"
						scrollable />
					<br />
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn elevation="0" text @click="hideModal">
						{{ $t("labels.btnCancel") }}
					</v-btn>
					<v-btn
						:disabled="errorMessage.length > 0"
						elevation="0"
						color="primary"
						@click="saveModal">
						{{ $t("labels.btnSave") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { snakeToCamel } from "../../helpers/string_utils";
import posColumnsInfo from "../../data/posColumnsInfo";
import { VMoney } from "v-money";

import { EventBus } from "../../helpers/event_bus";

const INPUT_TYPES = {
	DATE: "date",
	CURRENCY: "currency",
	TEXT: "text",
	CNPJ: "cnpj",
	NUMBER: "number",
};

export default {
	directives: { money: VMoney },
	data: () => ({
		id: null,
		ariaValues: {
			id: "",
			column: "",
			value: "",
		},
		validateFn: null,
		showContent: false,
		INPUT_TYPES,
		inputType: null,
		errorMessage: "",
		refresh: 0,
		modal: false,
	}),
	computed: {
		distRegion() {
			const distData = this.$store.state.pos.view.distributorData;
			if (distData.region && distData.region == "laam") {
				return "laam";
			}
			return "br";
		},
		isLaam() {
			return this.distRegion == "laam";
		},
		config() {
			const decimal = this.isLaam ? "." : ",";
			const thousands = this.isLaam ? "," : ".";
			const prefix = this.isLaam ? "$ " : "R$ ";

			return {
				decimal,
				thousands,
				prefix,
				precision: 2,
				masked: false,
			};
		},
	},
	mounted() {
		EventBus.$on("show-pos-edit-modal", async (data) => {
			// check is there missing prices
			if (data.missingPriceCount == 0 && data.column == "total") {
				await this.$store.dispatch("dialog/show", {
					title: this.$t("labels.totalLockAlertTitle"),
					content: this.$t("labels.totalLockAlertContent"),
					confirm: true,
				});
				this.hideModal();
				return;
			}

			// check if the last missing price
			if (data.missingPriceCount == 1 && data.column == "price") {
				var response = await this.$store.dispatch("dialog/show", {
					title: this.$t("labels.totalPriceAlertTitle"),
					content: this.$t("labels.totalPriceAlertContent"),
				});
				if (!response) {
					this.hideModal();
					return;
				}
			}

			this.refresh++;
			this.ariaValues = data;
			this.showModal();
		});

		this.id = this.$route.params.id;
	},
	methods: {
		keyUpHandler(e) {
			// console.log(e);
			this.errorMessage = this.validateFn(this.ariaValues.value, this.isLaam);
		},
		snakeToCamel(value) {
			return snakeToCamel(value);
		},
		hideModal() {
			this.showContent = false;
			EventBus.$emit("refresh-pos-history");
		},
		showModal() {
			const columnCamelCase = this.snakeToCamel(this.ariaValues.column);
			

			// set input type
			if (
				this.ariaValues.column == "part_number" ||
				this.ariaValues.column == "branch_id" ||
				this.ariaValues.column == "city" ||
				this.ariaValues.column == "state" ||
				this.ariaValues.column == "country" ||
				this.ariaValues.column == "invoice_number" ||
				this.ariaValues.column == "currency_code" ||
				this.ariaValues.column == "end_user" ||
				this.ariaValues.column == "end_user_number" ||
				this.ariaValues.column == "end_user_order" ||
				this.ariaValues.column == "end_country_code" ||
				this.ariaValues.column == "end_user_vertical_market" ||
				this.ariaValues.column == "reseller_name" ||
				this.ariaValues.column == "disty_country_code" ||
				this.ariaValues.column == "fluke_model_noun" ||
				this.ariaValues.column == "sic_code" ||
				this.ariaValues.column == "postal_code"
			) {
				this.inputType = INPUT_TYPES.TEXT;
			} else if (this.ariaValues.column == "quantity") {
				this.inputType = INPUT_TYPES.NUMBER;
			} else if (
				this.ariaValues.column == "price" ||
				this.ariaValues.column == "unit_resale" ||
				this.ariaValues.column == "extended_resale" ||
				this.ariaValues.column == "total"
			) {
				this.inputType = INPUT_TYPES.CURRENCY;

				try {
					this.ariaValues.value = (+this.ariaValues.value).toFixed(2);
				} catch (e) {
					console.log(e);
				}
			} else if (
				this.ariaValues.column == "invoice_date" ||
				this.ariaValues.column == "end_user_invoice_date" ||
				this.ariaValues.column == "shipment_date"
			) {
				this.inputType = INPUT_TYPES.DATE;

				try {
					this.ariaValues.value = new Date(this.ariaValues.value)
						.toISOString()
						.substring(0, 10);
				} catch (e) {
					console.log(e);

					this.ariaValues.value = new Date().toISOString().substring(0, 10);
				}

				this.ariaValues.value = new Date(this.ariaValues.value)
					.toISOString()
					.substring(0, 10);
			} else {
				this.inputType = INPUT_TYPES.TEXT;
			}

			// set validation function
			this.validateFn = posColumnsInfo[columnCamelCase].validation;

			// pre validate data
			this.errorMessage = this.validateFn(this.ariaValues.value, this.isLaam);

			this.showContent = true;
		},
		async saveModal() {
			var payload = this.ariaValues;
			payload["region"] = this.distRegion;
			await this.$store.dispatch("pos/saveRow", payload);
			this.hideModal();
		},
	},
};
</script>
