<template>
	<div>
		<div v-if="history.length">
			<v-expansion-panels flat>
				<v-expansion-panel>
					<v-expansion-panel-header>
						{{ $t("pages.pos.view.history") }} ({{ history.length }})
						<template v-slot:actions>
							<v-icon color="primary">$expand</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div id="preview_table_pos_wrapper">
							<table id="preview_table_pos">
								<thead>
									<tr>
										<th>{{ $t("labels.action") }}</th>
										<th>{{ $t("labels.dataInsert") }}</th>
										<th>{{ $t("labels.user") }}</th>
										<th>{{ $t("labels.date") }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in history" :key="index">
										<td>{{ item.action }}</td>
										<td v-html="item.data"></td>
										<td>{{ item.user }}</td>
										<td>{{ item.created }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<br />
		</div>
	</div>
</template>

<script>
import DateFormater from "../../helpers/date_formater";
import { EventBus } from "../../helpers/event_bus";

const replacementTitles = {
	activity_cnae: "activityCnae",
	branch_id: "branchId",
	city: "city",
	country: "country",
	currency_code: "currencyCode",
	distributor_cnpj: "distributorCnpj",
	distributor_name: "distributorName",
	disty_country_code: "distyCountryCode",
	end_country_code: "endCountryCode",
	end_user_invoice_date: "endUserInvoiceDate",
	end_user_number: "endUserNumber",
	end_user_order: "endUserOrder",
	end_user_vertical_market: "endUserVerticalMarket",
	end_user: "endUser",
	extended_resale: "extendedResale",
	fluke_model_noun: "flukeModelNoun",
	invoice_date: "invoiceDate",
	invoice_number: "invoiceNumber",
	part_number: "partNumber",
	postal_code: "postalCode",
	price: "price",
	provider: "provider",
	quantity: "quantity",
	reseller_name: "resellerName",
	shipment_date: "shipmentDate",
	sic_code: "sicCode",
	state: "state",
	total_price: "totalPice",
	unit_resale: "unitResale",
	value: "value",
};

export default {
	data: () => ({
		id: null,
		history: [],
	}),
	mounted() {
		EventBus.$on("refresh-pos-history", () => {
			this.getData();
		});

		this.id = this.$route.params.id;
		this.getData();
	},
	methods: {
		async getData() {
			const result = await this.$store.dispatch("pos/history", this.id);
			var rowValues, partNumberData;
			this.history = result.map((item) => {
				item["action"] =
					item.actions == "replacing"
						? this.$t("pages.pos.history.fullReplace")
						: this.$t("pages.pos.history.fieldChange");
				item["created"] = DateFormater.fromDB(item.created_at);
				if (item.actions == "replacing") {
					item["data"] =
						item.data == "no_sell_period" ? "Período sem vendas" : item.data;
				} else {
					rowValues = JSON.parse(item.data);
					partNumberData =
						rowValues.column == "total"
							? ""
							: `Part Number: <strong>${rowValues.rel}</strong>, `;
					item["data"] = `${partNumberData}Campo: <strong>${
						replacementTitles[rowValues.column]
					}</strong>, Valor: <strong>${rowValues.value}</strong>`;
				}
				return item;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
#preview_table_pos_wrapper {
	overflow: auto;
	max-height: 400px;
	max-width: 100%;
}
#preview_table_pos {
	/* border-collapse: collapse; */
	width: 100%;
	border-spacing: 4px;

	th {
		text-align: left;
	}

	th,
	td {
		padding: 2px 10px;
		white-space: nowrap;
	}

	thead tr,
	tr:nth-child(even) {
		background-color: #f5f5f5;
	}

	.currency {
		text-align: right;
	}
}
</style>
