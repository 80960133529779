<template>
	<f-card>
		<v-row :key="refreshList">
			<v-col cols="12">
				<div v-if="products.length">
					<div id="preview_table_pos_wrapper">
						<table id="preview_table_pos">
							<thead>
								<tr>
									<th>*</th>
									<th v-for="(item, index) in columnOrder" :key="index">
										<!-- {{ $t(`pages.pos.view.tableHeader.${item}`) }} -->
										{{
											$t(
												`pages.pos.tableHeaders.${
													isLaam ? "laam" : "br"
												}.${snakeToCamel(item)}`
											)
										}}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, rowIndex) in sortedProducts" :key="rowIndex">
									<td>{{ rowIndex + 1 }}</td>
									<td
										v-for="(col, colIndex) in row"
										:key="colIndex"
										class="highlight-editmode"
										:aria-item-id="$store.state.pos.view.products[rowIndex].id"
										:aria-item-column="columnOrder[colIndex]"
										:aria-item-value="col">
										{{
											displayTableValue(
												col,
												isLaam,
												columnOrder[colIndex],
												getCurrency(columnOrder, row)
											)
										}}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-else>
					<h2>{{ $t("labels.withoutSales") }}</h2>
				</div>
			</v-col>
		</v-row>

		<v-row :key="refreshList + 'total'">
			<v-col cols="12" class="totalValue">
				{{ $t("labels.totalValue") }}
				<span
					class="highlight-editmode"
					aria-item-column="total"
					:aria-item-value="total">
					{{ displayTableValue(total, isLaam, "price") }}
				</span>
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
import { snakeToCamel } from "../../helpers/string_utils";
import { displayTableValue } from "../../helpers/table_utils";
import { EventBus } from "../../helpers/event_bus";

export default {
	data: () => ({
		refreshList: 0,
		id: null,
	}),
	computed: {
		/**
		 * @returns {boolean}
		 */
		isLaam() {
			const distData = this.$store.state.pos.view.distributorData;
			if (distData.region && distData.region == "laam") {
				return true;
			}
			return false;
		},
		columnOrder() {
			try {
				if (
					typeof this.$store.state.pos.view.products[0].provider !== "undefined"
				) {
					return [
						"part_number",
						"activity_cnae",
						"quantity",
						"price",
						"shipment_date",
						"provider",
						"end_user",
						"city",
						"state",
						"distributor_name",
						"distributor_cnpj",
					];
				} else {
					return [
						"part_number",
						"quantity",
						"price",
						"unit_resale",
						"extended_resale",
						"branch_id",
						"city",
						"state",
						"country",
						"postal_code",
						"invoice_date",
						"invoice_number",
						"currency_code",
						"end_user",
						"end_user_number",
						"end_user_order",
						"end_user_invoice_date",
						"end_country_code",
						"end_user_vertical_market",
						"reseller_name",
						"disty_country_code",
						"fluke_model_noun",
						"sic_code",
					];
				}
			} catch (e) {
				return [];
			}
		},
		sortedProducts() {
			return this.products.map((row) => {
				return this.columnOrder.map((index) => row[index]);
			});
		},
		products() {
			return this.$store.state.pos.view.products;
		},
		total() {
			return this.$store.state.pos.view.total;
		},
		missingPriceCount() {
			return this.products.filter((p) => !p.price).length;
		},
	},
	mounted() {
		EventBus.$on("refresh-pos-history", () => {
			this.cleanAllItems();
			this.refreshList++;
			this.getData();
		});

		this.id = this.$route.params.id;
		this.getData();
	},
	methods: {
		getCurrency(columns, row) {
			let index = columns.indexOf("currency_code");
			if (index == -1) {
				return false;
			}

			return row[index];
		},
		displayTableValue(value, isLaam, column, hasFormat = false) {
			return displayTableValue(value, isLaam, column, hasFormat);
		},
		snakeToCamel(value) {
			return snakeToCamel(value);
		},
		prepareEdit() {
			const items = document.querySelectorAll(".highlight-editmode");
			items.forEach((item, index) => {
				// avoid stack of listeners
				var old_element = item;
				var new_element = old_element.cloneNode(true);
				old_element.parentNode.replaceChild(new_element, old_element);

				new_element.addEventListener("click", () =>
					this.activateItem(new_element)
				);
			});
		},
		cleanAllItems() {
			const items = document.querySelectorAll(".highlight-editmode");
			items.forEach((rm_item) => rm_item.classList.remove("active"));
		},
		activateItem(item) {
			this.cleanAllItems();

			let column = item.getAttribute("aria-item-column");
			const value = item.getAttribute("aria-item-value");

			let id;
			if (column == "total") {
				id = this.id;
			} else {
				id = item.getAttribute("aria-item-id");
			}
			item.classList.add("active");

			EventBus.$emit("show-pos-edit-modal", {
				id,
				column,
				value,
				missingPriceCount: this.missingPriceCount,
			});
		},
		async getData() {
			await this.$store.dispatch("pos/get", this.id);

			this.prepareEdit();
		},
	},
};
</script>

<style lang="scss" scoped>
#preview_table_pos_wrapper {
	overflow: auto;
	max-height: 400px;
	max-width: 100%;
}
#preview_table_pos {
	/* border-collapse: collapse; */
	width: 100%;
	border-spacing: 4px;

	th {
		text-align: left;
	}

	th,
	td {
		padding: 2px 10px;
		white-space: nowrap;
	}

	thead tr,
	tr:nth-child(even) {
		background-color: #f5f5f5;
	}

	.currency {
		text-align: right;
	}
}
.totalValue {
	text-align: right;

	span {
		font-weight: bold;
		padding-left: 10px;
		font-size: 1.2em;
	}
}
.highlight-editmode {
	&.active {
		background-color: black;
		color: white;
		font-weight: bold;
	}

	&:hover {
		background-color: #ffc107;
		cursor: pointer;
		color: white;
	}
}
</style>
