<template>
	<div>
		<f-default-header
			:title="$t('pages.pos.title')"
			:subtitle="$t('pages.pos.view.subtitle')" />

		<view-history />

		<view-list />

		<f-actions-btn-holder>
			<v-btn color="secondary" elevation="0" large @click="back">
				{{ $t("labels.btnBack") }}
			</v-btn>
			<v-btn color="primary" elevation="0" large @click="download">
				{{ $t("labels.btnDownload") }}
			</v-btn>
		</f-actions-btn-holder>
		<f-delete-button @click.native="removeItem">
			{{ $t("labels.btnDeletePOS") }}
		</f-delete-button>

		<edit-dialog />
	</div>
</template>

<script>
import XLSX from "xlsx";
import { snakeToCamel } from "../../helpers/string_utils";
import history from "../../components/pos/History.vue";
import ViewList from "../../components/pos/ViewList.vue";
import CView from "../../components/contact/View.vue";
import EditDialog from "../../components/pos/EditDialog.vue";
import { EventBus } from "../../helpers/event_bus";

export default {
	components: {
		"view-history": history,
		ViewList,
		CView,
		EditDialog,
	},
	data: () => ({
		id: null,
	}),
	computed: {
		// set the column order based on the current products
		distRegion() {
			const distData = this.$store.state.pos.view.distributorData;
			if (distData.region && distData.region == "laam") {
				return "laam";
			}
			return "br";
		},
		products() {
			return this.$store.state.pos.view.products;
		},
	},
	mounted() {
		this.id = this.$route.params.id;
		this.getData();
	},
	methods: {
		snakeToCamel(value) {
			return snakeToCamel(value);
		},
		async getData() {
			// await this.$store.dispatch("pos/get", this.id);
		},
		back() {
			this.$router.push({
				name: "pos-list",
			});
		},
		async download() {
			const distRegion = this.distRegion;
			const data = this.products.map((item) => {				
				if(distRegion == "laam") {
					let dateFormated = new Date(item.invoice_date) ? new Date(item.invoice_date).toLocaleDateString() : "";
					return {
						[this.$t("pages.pos.tableHeaders.laam.partNumber")]: item.part_number,
						[this.$t("pages.pos.tableHeaders.laam.quantity")]: item.quantity,
						[this.$t("pages.pos.tableHeaders.laam.price")]: item.unit_resale,
						[this.$t("pages.pos.tableHeaders.laam.unitResale")]: item.unit_resale,
						[this.$t("pages.pos.tableHeaders.laam.branchId")]: item.branch_id,
						[this.$t("pages.pos.tableHeaders.laam.city")]: item.city,
						[this.$t("pages.pos.tableHeaders.laam.state")]: item.state,
						[this.$t("pages.pos.tableHeaders.laam.country")]: item.disty_country_code,
						[this.$t("pages.pos.tableHeaders.laam.postalCode")]: item.postal_code,
						[this.$t("pages.pos.tableHeaders.laam.invoiceDate")]: dateFormated,
						[this.$t("pages.pos.tableHeaders.laam.invoiceNumber")]: item.invoice_number,
						[this.$t("pages.pos.tableHeaders.laam.currencyCode")]: item.currency_code,
						[this.$t("pages.pos.tableHeaders.laam.endUser")]: item.end_user,
						[this.$t("pages.pos.tableHeaders.laam.endUserNumber")]: item.end_user_number,
						[this.$t("pages.pos.tableHeaders.laam.endUserOrder")]: item.end_user_order,
						[this.$t("pages.pos.tableHeaders.laam.endUserInvoiceDate")]: item.end_user_invoice_date,
						[this.$t("pages.pos.tableHeaders.laam.endUserInvoiceNumber")]: item.end_user_invoice_number,
						[this.$t("pages.pos.tableHeaders.laam.endCountryCode")]: item.end_country_code,
						[this.$t("pages.pos.tableHeaders.laam.endUserVerticalMarket")]: item.end_user_vertical_market,
						[this.$t("pages.pos.tableHeaders.laam.resellerName")]: item.reseller_name,
						[this.$t("pages.pos.tableHeaders.laam.distyCountryCode")]: item.disty_country_code,
						[this.$t("pages.pos.tableHeaders.laam.flukeModelNoun")]: item.fluke_model_noun,
						[this.$t("pages.pos.tableHeaders.laam.sicCode")]: item.sic_code,
					};
				}

				const date = new Date(item.shipment_date)? new Date(item.shipment_date).toLocaleDateString() : "";
				return {
					[this.$t("labels.supplier")]: item.provider,
					PartNumber: item.part_number,
					[this.$t("labels.qtd")]: item.quantity,
					[this.$t("labels.endUser")]: item.end_user,
					[this.$t("labels.cnae")]: item.activity_cnae,
					[this.$t("labels.city")]: item.city,
					[this.$t("labels.state")]: item.state,
					[this.$t("labels.date")]: date,
					[this.$t("labels.distributorName")]: item.distributor_name,
					[this.$t("labels.distributorCompanyId")]: item.distributor_cnpj,
					[this.$t("labels.value")]: item.price,
				};
			});

			const filename = "download.xlsx";
			const ws = XLSX.utils.json_to_sheet(data);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "sheet");

			XLSX.writeFile(wb, filename);
		},
		async removeItem() {
			var response = await this.$store.dispatch("dialog/show", {
				title: this.$t("labels.remove"),
				content: this.$t("alerts.posRemove"),
			});
			if (response) {
				await this.$store.dispatch("pos/remove_data", {
					id: this.id,
					region: this.distRegion,
				});
				this.back();
			}
		},
	},
};
</script>
