<template>
    <div class="ticket-response" :class="{ admin: isAdmin }">
        <div class="tr-date">
            {{date}}
        </div>
        <div class="tr-user">
            <span class="fluke-side" v-if="isAdmin">[ FLUKE ] </span>
            {{user}}:
        </div>
        <div class="tr-message">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: [ 
        'user',
        'isAdmin',
        'date'
    ]
}
</script>